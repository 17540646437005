<template>
<div class="section" style="padding-top: 10rem">
    <div class="columns">
        <div class="column is-4-desktop">
            <h3 class=" mb-3" >Personal Data</h3>
            <span>The info here will be shown at the bottom of the page, and in the contact page (its shows exactly how you type it)</span>
            <input-box
            :placeholder="$t('admin_edit_email')"
            type="text"
            :modelValue="siteInfo.email"
            @update:modelValue="siteInfo.email = $event"
            class="is-input-half"
            label="Email"
          />
            <input-box
            :placeholder="$t('admin_edit_address')"
            type="text"
            :modelValue="siteInfo.address"
            @update:modelValue="siteInfo.address= $event"
            class="is-input-half"
            label="Address"
          />
            <input-box
            :placeholder="$t('admin_edit_phone')"
            type="text"
            :modelValue="siteInfo.phone"
            @update:modelValue="siteInfo.phone = $event"
            class="is-input-half"
            label="Phone Number"
          />
          <input-box
            :placeholder="$t('admin_edit_time')"
            type="text"
            :modelValue="siteInfo.time"
            @update:modelValue="siteInfo.time = $event"
            class="is-input-half"
            label="Open hours"
          />
            <button @click="updateSiteData()" class="button is-success is-rounded is-small">Save</button>
        </div>
        <div class="column is-8-desktop">
          <vueCal
            :locale="$i18n.locale === `est` ? 'et' : 'en'"
            :time="false"
            events-on-month-view
            :events=bookedDates
            active-view="month"
            :min-date="new Date().toISOString().substr(0, 10)"
            xsmall>
            <template #arrow-prev>
              <i class="icon material-icons">Back</i>
            </template>
            <template #arrow-next>
              <i class="icon material-icons">Next</i>
            </template>
          </vueCal>
          <div class="is-flex flex-column flex-lg-row is-justify-content-space-between is-flex-wrap-wrap">
            <input-box
              placeholder="booked date (start)"
              type="date"
              :modelValue="booking.start"
              @update:modelValue="booking.start = $event"
              class="is-input-half mt-4"
              label="book new starting date"
              style="max-width: 40rem; width: 100%"
            />
            <input-box
            placeholder="booked date (end)"
            type="date"
            :modelValue="booking.end"
            @update:modelValue="booking.end = $event"
            class="is-input-half mt-4 "
            label="book new ending date"
            style="max-width: 40rem; width: 100%"
          />
          <input-box
            placeholder="This will be visible to customers"
            type="text"
            :modelValue="booking.title"
            @update:modelValue="booking.title = $event"
            class="is-input-half mt-4 "
            label="What is being rented out?"
            style="max-width: 40rem; width: 100%"
          />
          
          <input-box
            placeholder="this will not be visible to customers"
            type="text"
            :modelValue="booking.content"
            @update:modelValue="booking.content = $event"
            class="is-input-half mt-4 "
            label="Customer name + extra info"
            style="max-width: 40rem; width: 100%"
          />
          </div>
          <Button @click="saveBooking()" class="button is-success mt-3">Save booking</Button>
        </div>
    </div>
    <div v-if="reviewsList">
      <span class="mb-2">Approved reviews will be shown on home page.</span>
        <div>
            <button class="is-clickable button is-small is-rounded mr-2" v-if="!showHide" @click="sortReviews(true)">show approved</button>
            <button class="is-clickable button is-small is-rounded mr-2"  v-else @click="sortReviews(false)">hide approved</button>
            <button class="is-clickable button is-small is-rounded" @click="sortReviews()">Show all</button>
        </div>
        <div class="mt-4 columns">
            <ul class="column is-12-tablet is-6-desktop">
              <h3>All reviews</h3>
                <li class=" is-justify-content-space-between is-flex" v-for="review in reviewsList" :key="review">
                    <div class="is-flex is-flex-direction-column">
                        <div class="is-flex-tablet is-flex-direction-column">
                          <p class="fit-content ml-1 mr-2 has-text-right has-text-weight-bold">{{review.author}}:</p>
                          <div>
                              <span class="fa fa-star" :class="review.rating >= 1 ? 'checked' : ''"></span>
                              <span class="fa fa-star" :class="review.rating >= 2 ? 'checked' : ''"></span>
                              <span class="fa fa-star" :class="review.rating >= 3 ? 'checked' : ''"></span>
                              <span class="fa fa-star" :class="review.rating >= 4 ? 'checked' : ''"></span>
                              <span class="fa fa-star" :class="review.rating >= 5 ? 'checked' : ''"></span>
                          </div>
                      </div>
                      <div class="is-flex is-flex-direction-column">
                        <p>{{review.message}}</p>
                        <p>{{review.date}}</p>
                      </div>
                    </div>
                    <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                        <button class="is-success button is-small is-rounded" v-if="!review.approved" @click="updateReview(review.id, true)">Approve</button>
                        <button class="is-danger button is-small is-rounded" v-if="review.approved" @click="updateReview(review.id, false)">un-approve</button>
                        <button class="button mt-2 is-danger is-small is-rounded is-outlined" @click="deleteReview(review.id)">
                          <span>Delete</span>
                          <span class="icon is-small">
                            <i class="fas fa-times"></i>
                          </span>
                        </button>
                    </div>
                  </li>
            </ul>
            <div class="column is-12-tablet is-6-desktop">
              <ul>
                <h3>All bookings</h3>
                <li v-for="booking in bookedDates" :key="booking" class="mb-4 is-flex is-justify-content-space-between">
                  <div class="is-flex">
                    <p style="width: 10rem;">{{booking.title}}:</p>
                    <p>{{booking.content}}</p>
                  </div>
                  <div class="is-flex">
                    <p class="mr-4">From: {{booking.start}} To:  {{booking.end}}</p>
                    <button @click="deleteBooking(booking.id)" class="button is-small is-rounded is-danger">Delete booking</button>
                  </div>
                </li>
              </ul>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";
import router from '../../router';
import { getSiteData, updateData, getData, updateReview, deleteReview, getBookedDates, setBookedDate, deleteBooking } from "../../firebase/FirestoreAPI";
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
export default {
    components: {
      VueCal,
    },

    data() {
        return {
            siteInfo: {},
            reviews: '',
            reviewsList: '',
            showHide: false,
            booking: {},
            bookedDates: []
        }
    },
    methods: {
        getDate(date) {
          date = date.split('T')
          return date[0]
        },

        async getSiteData() {
          let data = await getSiteData()
            if(data) {
              console.log(data)
              this.siteInfo = data
            }
        },

        async updateSiteData() {
            let payload = this.siteInfo
            await updateData(payload, "siteData", "site_info", "Data updated")
            .then(() => {
              this.getSiteData()
            })
        },

        async getReviews() {
          this.reviews = []
          let data = await getData("reviews")
          if(data) {
            this.reviews = data
            console.log(this.reviews)
            this.sortReviews()
          }
        },

        async deleteBooking(id) {
          deleteBooking("booked_dates", id)
          .then(() => {
            this.getBookings()
          })
        },

        async updateReview(id, state) {
            await updateReview(state, "reviews", id)
            .then(() => {
              this.getReviews()
            })
        },

        sortReviews(state){
            if(state == false || state == true){
                console.log(state)
                this.showHide = state
                this.reviewsList = this.reviews
                return this.reviewsList = this.reviews.filter((i) => i.approved == state)
            } else{
                this.showHide = null
                return this.reviewsList = this.reviews
            }
        },
        
        async getBookings(){
            let data = await getBookedDates()
            let todayDate = new Date().toISOString().slice(0, 10);
            let deleteOld = data.filter((i) => i.end < todayDate)
            
            if(deleteOld){
              deleteOld.map((obj) => {
                deleteBooking("booked_dates", obj.id)
              })
            }
            
            if(data) {
              this.bookedDates = data;
            }

            
        },

        async saveBooking() {
          await setBookedDate(this.booking, "booked_dates")
          .then(() => {
            this.getBookings()
          })
        },

        async deleteReview(id){
          await deleteReview("reviews", id)
          .then(() => {
            this.getReviews()
          })
        },

        checkUser(){
          const auth = getAuth();
          onAuthStateChanged(auth, (user) => {
            if (!user) {
              router.push("/admin/login")
            }
          });
        },

    },
      mounted() {
        this.checkUser()
        this.getBookings()
        this.getSiteData()
        this.getReviews()
  },
}
</script>
<style lang="scss">
.vuecal{
  height: fit-content!important;
}
    .checked {
      color: orange;
    }
    .fit-content{
      width: fit-content;
    }
    .vuecal__cell{
      color: green;
        &--disabled {
      text-decoration: line-through;
      color: red;
      }
      &--has-events {background-color: rgba(255, 0, 0, 0.45);}
      &-events-count {display: none;}
      }
</style>